<template lang="">
  <el-dialog v-model="dialogFormVisible" title="购买/激活课程" width="60%">
    <el-form ref="codeFormRef" :inline="true" :model="codeForm" :rules="rules">
      <el-form-item prop="code">
        <el-input v-model="codeForm.code" placeholder="请输入16位激活码" autocomplete="off" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click.prevent="handleActivate">激活</el-button>
      </el-form-item>
    </el-form>
    <div style="max-width: 600px">
      <div class="note-alert">
        <el-icon><InfoFilled /></el-icon>
        <strong>注意：</strong> 如果提示激活码无效，请登录新版机考网站尝试，  <a href="https://www.en51.com/test/ielts/mock" target="_blank">新版九分达人机考</a> <el-icon><Right /></el-icon>。
      </div>
    </div>
    <el-divider />
    <div class="qrcode_container">
      <span class="qrcode_img">
        <img :src="require('@/assets/images/qrcode2.png')">
      </span>
      <div class="introduce">
        <span>还没有课程激活码？<strong>微信扫码进入【NC嗨书】知识店铺购买课程</strong></span>
        <ol>
          <li>购买课程前，请绑定微信或手机号，激活码会自动发送到微信和手机，否则将收不到激活码；</li>
          <li>购买成功后，请及时扫码加客服获取激活码获取方式；</li>
          <li>激活后，可以解锁对应课程所有内容及配套资料;</li>
          <li>数字产品，不支持退款哦。</li>
        </ol>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { activateCard } from '@/apis/member'

const codeForm = reactive({
  code: undefined
})
const codeFormRef = ref(undefined)
const dialogFormVisible = ref(false)

const validateCode = (rule, value, callback) => {
  if (value === '' || value === undefined || value.length !== 16) {
    callback(new Error('请输入16位激活码！'))
  } else {
    callback()
  }
}

const store = useStore()

const rules = reactive({
  code: [{ required: true, trigger: 'blur', validator: validateCode }]
})

const handleActivate = () => {
  codeFormRef.value.validate(valid => {
    if (valid) {
      activateCard(codeForm.code).then(res => {
        if (res.data.data.code === 0) {
          ElMessage.success('激活成功！')
          codeFormRef.value.resetFields()
          store.dispatch('getMemberInfo')
          store.dispatch('getWritingInfo')
          dialogFormVisible.value = false
        } else {
          ElMessage.error(res.data.data.msg)
          codeFormRef.value.resetFields()
        }
      })
    }
  })
}

const show = () => {
  dialogFormVisible.value = true
}

defineExpose({
  show
})

</script>
<style lang="scss" scoped>
.qrcode_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.qrcode_container .qrcode_img {
  margin-right: 30px;
}

.qrcode_container .qrcode_img img {
  display: block;
  width: 180px;
  height: auto;
}

.qrcode_container .introduce ol {
  margin: 8px 0 0 0;
  padding: 0;
  padding-inline-start: 20px;
  line-height: 1.5rem;
}
.note-alert{
  background-color:#FFF3E0;
  color: #E65100;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: start;
  align-items: center;
  a{
    color: #01579B;
    text-decoration: underline;
    margin-right: 4px;
  }
  a:hover{font-weight: bold;}
  strong{
    margin-left: 4px;
  }
}
</style>
